import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { TopBarContext } from "./constants";

import "@parataxic/shared-ui/src/styles/TopBar.css";

interface IProps {
  logo?: unknown;
}

const MenuItem: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <div
    //   display="inline-flex"
    //   cursor="pointer"
    //   ml={{
    //     base: 3,
    //     lg: 5,
    //   }}
    //   fontSize={{ base: "0.8rem", md: "1em" }}
    //   height="100%"
    //   placeItems="center"
    // color="white"
    className="menu-item"
    {...props}
  >
    {children}
  </div>
);

export const TopBarWrapper: React.FC = ({ children }) => {
  return (
    <header
      className="top-bar"
      role="navigation"
      // display="flex"
      // flexDirection="row"
      // bg="blue.700"
      // px={{ base: 0, md: 5 }}
      // mb={{ base: 0, lg: 5 }}
      // h="50px"
      // as="header"
      // role="navigation"
      // alignItems="center"
      // placeItems="center"
      // justifyContent="center"
      // textAlign="center"
    >
      <div
        // maxWidth="1000px"
        // margin="0 auto"
        // display="flex"
        // flex="auto"
        // height="100%"
        // alignItems="center"
        // justifyContent="center"
        className="top-bar-inner"
      >
        {children}
      </div>
    </header>
  );
};

export const TopBar: React.FC<IProps> = ({ logo }) => {
  const { content } = React.useContext(TopBarContext);

  return (
    <TopBarWrapper>
      {content ? (
        content
      ) : (
        <div
          style={{
            display: "flex",
            placeItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              cursor: "pointer",
              height: "100%",
              marginLeft: "0.5rem",
              marginRight: "auto",
              alignSelf: "left",
            }}
            className="logo-item"
          >
            <Link
              to="/"
              style={{
                height: "40px",
                alignSelf: "center",
              }}
              className="logo-item--link"
            >
              <GatsbyImage
                image={logo.childImageSharp.gatsbyImageData}
                alt="HSKFlashcards"
              />
            </Link>
          </div>
          <MenuItem
            style={{
              marginLeft: "var(--size-3)",
            }}
          >
            <Link to="/about/">About</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/downloads/">Downloads</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/resources/">Resources</Link>
          </MenuItem>
          <MenuItem marginRight="1rem">
            <Link to="/updates/">Updates</Link>
          </MenuItem>
        </div>
      )}
    </TopBarWrapper>
  );
};

export default TopBar;
