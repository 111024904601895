import React from "react";
import { useSwipeable } from "react-swipeable";

import {
  faArrowLeft,
  faBackward,
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
  faFastBackward,
  faFastForward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, navigate } from "gatsby";
import { useBreakpoint } from "use-breakpoint";

import { SEO, SiteLink } from "@parataxic/shared-ui";

import { TopBarWrapper } from "../components/Layout/TopBar";

import "./Vocab.css";

const BREAKPOINTS = {
  base: 0,
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

export interface PersonalityvocabViewProps {
  readonly pageContext: {
    readonly vocab: GatsbyTypes.Vocab;
    readonly series: GatsbyTypes.Series;
    readonly deck: GatsbyTypes.Deck;
  };
  readonly overrides?: {
    readonly description?: string;
  };
}

// const keyMap = {
//   FLIP_CARD: ["up", "down", "j", "k", "w", "s", "space"],
//   NEXT_CARD: ["right", "l", "d"],
//   PREVIOUS_CARD: ["left", "h", "a"],
// };

export interface State {
  showAnswer: boolean;
  vocab: GatsbyTypes.Vocab;
  series: GatsbyTypes.Series;
  deck: GatsbyTypes.Deck;
}
export enum ActionType {
  FLIP_CARD = "FLIP_CARD",
  SHOW_FRONT = "SHOW_FRONT",
  SHOW_BACK = "SHOW_BACK",
  SET_VOCAB = "SET_VOCAB",
  SET_DECK = "SET_DECK",
  SET_SERIES = "SET_SERIES",
  SET = "SET",
}
type Action =
  | {
      type: ActionType.FLIP_CARD;
    }
  | {
      type: ActionType.SHOW_FRONT;
    }
  | {
      type: ActionType.SHOW_BACK;
    }
  | {
      type: ActionType.SET_VOCAB;
      vocab: State["vocab"];
    }
  | {
      type: ActionType.SET_SERIES;
      series: State["series"];
    }
  | {
      type: ActionType.SET_DECK;
      deck: State["deck"];
    }
  | {
      type: ActionType.SET;
      deck?: State["deck"];
      series?: State["series"];
      vocab?: State["vocab"];
    };

export const DEFAULT_STATE: State = {
  showAnswer: false,
  deck: {},
  series: {},
  vocab: {},
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FLIP_CARD: {
      return {
        ...state,
        ...action,
        showAnswer: !state.showAnswer,
      };
    }
    case ActionType.SHOW_FRONT: {
      return {
        ...state,
        ...action,
        showAnswer: true,
      };
    }
    case ActionType.SHOW_BACK: {
      return {
        ...state,
        ...action,
        showAnswer: false,
      };
    }
    case ActionType.SET: {
      return {
        ...state,
        ...action,
      };
    }
    case ActionType.SET_DECK: {
      return {
        ...state,
        ...action,
      };
    }
    case ActionType.SET_SERIES: {
      return {
        ...state,
        ...action,
      };
    }
    case ActionType.SET_VOCAB: {
      return {
        ...state,
        ...action,
      };
    }
  }
};

export const VocabContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
  setShowAnswer: (showAnswer: boolean) => void;
}>({ state: DEFAULT_STATE, dispatch: () => undefined });

export const VocabProvider: React.FC<{
  deck: State["deck"];
  vocab: State["deck"];
  series: State["series"];
}> = ({ children, deck, vocab, series }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...DEFAULT_STATE,
    deck,
    vocab,
    series,
  });

  const setShowAnswer = (showAnswer: boolean) => {
    dispatch({
      type: showAnswer ? ActionType.SHOW_FRONT : ActionType.SHOW_BACK,
    });
  };
  return (
    <VocabContext.Provider
      value={{
        setShowAnswer,
        ...state,
        dispatch,
      }}
    >
      {children}
    </VocabContext.Provider>
  );
};

const useVocab = () => {
  const { deck, vocab, series, setShowAnswer, showAnswer, dispatch } =
    React.useContext(VocabContext);

  if (deck === undefined) {
    throw new Error("useVocab must be used within a VocabProvider");
  }

  return {
    setShowAnswer,
    showAnswer,
    flipCard: () => {
      dispatch({ type: ActionType.FLIP_CARD });
    },

    // Meta
    deckLabel: series.nested ? `${deck.lesson}.${deck.level}` : deck.level,
    // Where are we
    hasPreviousDeck: deck.level > 1,
    hasNextDeck: deck.level < series.deckCount,
    onFirstDeck: series.level == 1,
    onLastDeck: series.level == series.deckCount,
    backLinkDeck: `${series.relativeUrl}/${parseInt(deck.level, 0) - 1}/1`,
    nextLinkDeck: `${series.relativeUrl}/${parseInt(deck.level, 0) + 1}/1`,
    hasPrevious: vocab.card_number > 1,
    hasNext: vocab.card_number < deck.vocabCount,
    backLink: `${deck.relativeUrl}/${vocab.card_number - 1}`,
    nextLink: `${deck.relativeUrl}/${vocab.card_number + 1}`,
    firstLink: `${deck.relativeUrl}/1`,
    lastLink: `${deck.relativeUrl}/${deck.vocabCount}`,
    onLastCard: vocab.card_number == deck.vocabCount,
    onFirstCard: vocab.card_number == 1,
  };
};

export const TopBar: React.FC = ({ deck, series, vocab }) => {
  const disableLinkProps = {
    onClick: (e) => {
      e.preventDefault();
    },
    onMouseOver: (e) => {
      e.preventDefault();
    },
  };

  const {
    showAnswer,
    flipCard,
    deckLabel,
    hasPreviousDeck,
    hasNextDeck,
    backLinkDeck,
    nextLinkDeck,
    hasPrevious,
    hasNext,
    backLink,
    firstLink,
    lastLink,
    onFirstCard,
    onLastCard,
  } = useVocab();

  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");

  React.useEffect(() => void 0, [flipCard]);

  return (
    <TopBarWrapper>
      <div
        style={{
          display: "flex",
          width: "100%",
          placeItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          height: "100%",
          textAlign: "center",
          color: "white",
          position: "relative",
          paddingLeft: breakpoint == "sm" ? ".5rem" : 0,
          paddingRight: breakpoint == "sm" ? ".5rem" : 0,
        }}
        className="menu-item"
      >
        <span
          style={{
            fontSize: "1rem",
            alignSelf: "left",
            position: "absolute",
            left: ".5rem",
          }}
        >
          <SiteLink href={series.relativeUrl}>
            <FontAwesomeIcon icon={faArrowLeft} size="1x" />
          </SiteLink>
        </span>
        {onFirstCard ? (
          <SiteLink
            href={hasPreviousDeck && onFirstCard ? backLinkDeck : "#"}
            {...(hasPreviousDeck && onFirstCard ? {} : disableLinkProps)}
            style={{
              display:
                (!onFirstCard && !hasPreviousDeck) ||
                ["base", "sm"].includes("breakpoint")
                  ? "none"
                  : "inline",
              paddingRight: "1rem",
              marginLeft: ["base", "sm"].includes("breakpoint")
                ? "auto"
                : "unset",
            }}
          >
            <FontAwesomeIcon icon={faFastBackward} size="1x" />
          </SiteLink>
        ) : (
          <SiteLink
            href={!onFirstCard ? firstLink : "#"}
            {...(!onFirstCard ? {} : disableLinkProps)}
            style={{
              display:
                onFirstCard || ["base", "sm"].includes("breakpoint")
                  ? "none"
                  : "inline",
              paddingRight: "1rem",
              marginLeft: ["base", "sm"].includes("breakpoint")
                ? "auto"
                : "unset",
            }}
          >
            <FontAwesomeIcon icon={faBackward} size="1x" />
          </SiteLink>
        )}

        <SiteLink
          href={hasPrevious ? backLink : "#"}
          disabled={!hasPrevious}
          {...(hasPrevious ? {} : disableLinkProps)}
          style={{
            paddingRight: "1rem",
            color: !hasPrevious ? "var(--color-gray-800)" : "white",
          }}
        >
          <FontAwesomeIcon
            icon={faCaretLeft}
            size="1x"
            disabled={!hasPrevious}
          />
        </SiteLink>
        <span
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "0.9rem",
            minWidth: ["base", "sm"].includes("breakpoint") ? "8rem" : "16rem",
            maxWidth: ["base", "sm"].includes("breakpoint")
              ? "12rem"
              : "initial",
          }}
        >
          <span style={{ fontSize: "1rem" }}>{series.name_long}</span>
          <br />
          <span
            style={{
              fontSize: "0.8rem",
              display: "flex",
              paddingTop: "0.3rem",
              justifyContent: "space-between",
              paddingLeft: ["base", "sm"].includes("breakpoint") ? "1rem" : 0,
              paddingRight: ["base", "sm"].includes("breakpoint") ? "1rem" : 0,
            }}
          >
            <div style={{ marginRight: "auto" }}>Deck {deckLabel}</div>

            <div
              style={{
                textAlign: "center",
                flexGrow: "1",
                paddingTop: ".1rem",
              }}
              className="hover-white"
              // NOTE: This has a workaround using onClick in the body component, fix later
              onClick={flipCard}
              alt="Show"
              id="toggle-show"
            >
              <FontAwesomeIcon
                icon={showAnswer ? faEyeSlash : faEye}
                size="1x"
                disabled={!hasNext}
              />
            </div>
            <div style={{ marginLeft: "auto", textAlign: "right" }}>
              {vocab.card_number} / {deck.vocabCount}
            </div>
          </span>
        </span>
        <SiteLink
          href={hasNext ? `${deck.relativeUrl}/${vocab.card_number + 1}` : "#"}
          disabled={!hasNext}
          {...(hasNext ? {} : disableLinkProps)}
          style={{
            paddingRight: "1rem",
            color: !hasNext ? "var(--color-gray-800)" : "white",
          }}
          className="menu-item"
        >
          <FontAwesomeIcon icon={faCaretRight} size="1x" disabled={!hasNext} />
        </SiteLink>

        {onLastCard ? (
          <SiteLink
            href={hasNextDeck && onLastCard ? nextLinkDeck : "#"}
            {...(hasNextDeck && onLastCard ? {} : disableLinkProps)}
            paddingLeft="1rem"
            visibility={hasNextDeck && onLastCard ? "visible" : "hidden"}
            display={{
              base: "none",
              xs: "inline",
            }}
            color="white"
            className="menu-item"
          >
            <FontAwesomeIcon icon={faFastForward} size="1x" />
          </SiteLink>
        ) : (
          <SiteLink
            href={!onLastCard ? lastLink : "#"}
            {...(!onLastCard ? {} : disableLinkProps)}
            style={{
              display:
                onLastCard || ["base", "sm"].includes("breakpoint")
                  ? "none"
                  : "inline",
              paddingRight: "1rem",
              marginLeft: ["base", "sm"].includes("breakpoint")
                ? "auto"
                : "unset",
            }}
            className="menu-item"
          >
            <FontAwesomeIcon icon={faForward} size="1x" />
          </SiteLink>
        )}
      </div>
    </TopBarWrapper>
  );
};

const View: React.FC<PersonalityvocabViewProps> = ({ deck, vocab, series }) => {
  const {
    showAnswer,
    flipCard,
    hasPrevious,
    hasNext,
    backLink,
    nextLink,
    deckLabel,
  } = useVocab();

  const seoProps: { description?: string } = {};
  seoProps.description = `Flashcard for ${vocab.zh_simp}`;
  let pageTitle = `${vocab.zh_simp}`;
  if (vocab.zh_trad) {
    pageTitle += ` (${vocab.zh_trad})`;
    seoProps.description += ` (${vocab.zh_trad})`;
  }
  seoProps.description += `. Sounds like ${vocab.zh_pinyin}`;
  pageTitle += `: ${vocab.zh_pinyin}`;
  if (
    vocab?.part_of_speech?.en_long &&
    vocab?.part_of_speech?.en_long !== "Unknown"
  ) {
    seoProps.description += `, ${vocab.part_of_speech.en_long}.`;
  }
  pageTitle += ` - Deck ${deckLabel}, ${series.name_short} Flashcards`;
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");

  const downHandler = React.useCallback(
    (e) => {
      if (
        e?.target?.nodeName === "INPUT" ||
        e?.target?.getAttribute?.("role") === "search"
      ) {
        // Don't catch when searching
        return;
      }
      e.preventDefault();

      if (
        hasPrevious &&
        (e.key == "a" || e.key == "h" || e.key == "ArrowLeft")
      ) {
        void navigate(backLink);
      } else if (
        hasNext &&
        (e.key == "d" || e.key == "l" || e.key == "ArrowRight")
      ) {
        void navigate(nextLink);
      } else if (e.key == "s" || e.key == "j" || e.key == "ArrowDown") {
        flipCard();
      } else if (e.key == "w" || e.key == "k" || e.key == "ArrowUp") {
        flipCard();
      }
    },
    [showAnswer]
  );

  const handlers = useSwipeable({
    onSwiped: ({ dir }) => {
      if (hasPrevious && dir === "Left") {
        void navigate(backLink);
      } else if (hasNext && dir === "Right") {
        void navigate(nextLink);
      } else if (dir === "Up") {
        flipCard();
      } else if (dir === "Down") {
        flipCard();
      }
    },
  });

  React.useEffect(() => {
    window.addEventListener(`keydown`, downHandler);
    return () => {
      window.removeEventListener(`keydown`, downHandler);
    };
  }, [vocab.id, pageTitle, downHandler, showAnswer]);

  React.useEffect(() => {
    // HACK: Fix show button click signal not working in TopBar
    const showBtn = document.getElementById("toggle-show");

    if (showBtn) {
      showBtn.addEventListener(`click`, flipCard);
      return () => {
        showBtn.removeEventListener(`click`, flipCard);
      };
    }
  }, [vocab.id, pageTitle, flipCard, showAnswer]);

  return (
    <>
      <SEO title={pageTitle} {...seoProps} />
      {showAnswer ? (
        <div
          style={{
            // height={{
            //   base: "100%",
            //   lg: "auto",
            // }}
            height: ["base", "xs", "md", "lg"].includes("breakpoint")
              ? "100%"
              : "auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            alignContent: "center",
            placeItems: "center",
            flexFlow: "column",
          }}
          {...handlers}
        >
          <div style={{ fontSize: "3rem" }}>{vocab.en}</div>

          {vocab.part_of_speech.en_long &&
            vocab.part_of_speech.en_long !== "Unknown" && (
              <div style={{ fontSize: "2rem", marginTop: "1rem" }}>
                {vocab.part_of_speech.en_long}
              </div>
            )}
        </div>
      ) : (
        <div
          style={{
            height: ["base", "xs", "md", "lg"].includes("breakpoint")
              ? "100%"
              : "auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            alignContent: "center",
            placeItems: "center",
            flexFlow: "column",
          }}
          {...handlers}
        >
          <h1 style={{ fontWeight: "500", fontSize: "5rem" }}>
            {vocab.zh_simp}
            {vocab.zh_trad && (
              <small style={{ color: "#333", paddingLeft: "1rem" }}>
                {vocab.zh_trad}
              </small>
            )}
          </h1>
          <div
            style={{
              fontSize: "3rem",
              marginTop: "1rem",
            }}
          >
            {vocab.zh_pinyin}
          </div>
        </div>
      )}
    </>
  );
};

export const query = graphql`
  query Vocab($vocabId: String!) {
    vocab(id: { eq: $vocabId }) {
      deck {
        id
        pk
        level
        lesson
        relativeUrl
        vocabCount
        series {
          deckCount
          vocabCount
          name_long
          name_short
          nested
          relativeUrl
        }
      }
      id
      pk
      zh_trad
      zh_simp
      zh_pinyin
      supplementary
      relativeUrl
      part_of_speech {
        en_long
        en_short
      }
      en
      card_number
    }
  }
`;

export const DefaultView: React.FC<PersonalityvocabViewProps> = ({
  data: {
    vocab: {
      deck: { series, ...deck },
      ...vocab
    },
  },
  ...rest
}) => (
  <VocabProvider vocab={vocab} deck={deck} series={series}>
    <View vocab={vocab} deck={deck} series={series} {...rest} />
  </VocabProvider>
);

export default DefaultView;
