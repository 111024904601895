import { useBreakpoint } from "use-breakpoint";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";

import { Card, SEO, SiteLink } from "@parataxic/shared-ui";
import { TopBarWrapper } from "../components/Layout/TopBar";

type Decks = GatsbyTypes.DecksEdge["node"];
type Series = GatsbyTypes.SeriesEdge["node"];

const CARD_VARIANT_BREAKPOINTS = { fullFlush: 0, elevated: 468 };
const BREAKPOINTS = {
  base: 0,
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

const DeckItem: React.FC<{ deck: Deck; series: Series }> = ({
  deck,
  series,
}) => {
  const { breakpoint: cardResponsiveVariant } = useBreakpoint(
    CARD_VARIANT_BREAKPOINTS,
    "elevated"
  );
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");

  return (
    <Card
      style={{
        // flexWrap={{ base: "wrap", md: "initial" }}
        flexWrap: ["base", "sm"].includes("breakpoint") ? "wrap" : "initial",
        // marginBottom={{ base: "0.8rem", sm: 0 }}
        marginBottom: ["base", "sm"].includes("breakpoint") ? ".8rem" : 0,
        display: "block",
        textAlign: "center",
        padding: breakpoint == "sm" ? "1rem" : 0,
      }}
      role="listitem"
      variant={cardResponsiveVariant}
    >
      <SiteLink
        href={`${deck.relativeUrl}/1`}
        style={{
          // fontSize={{ base: ".9rem", md: ".95rem", lg: "1rem" }}
          fontSize: "1rem",
          justifyContent: "space-between",
        }}
      >
        Lesson {series.nested ? `${deck.lesson}.${deck.level}` : deck.level}
      </SiteLink>
      <small style={{ display: "block" }}>({deck.vocabCount} cards)</small>
    </Card>
  );
};

export const TopBar = ({ series }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");

  return (
    <TopBarWrapper>
      <div
        style={{
          display: "flex",
          width: "100%",
          placeItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          height: "100%",
          textAlign: "center",
          color: "white",
          position: "relative",
          paddingLeft: breakpoint == "sm" ? ".5rem" : 0,
          paddingRight: breakpoint == "sm" ? ".5rem" : 0,
          // paddingLeft={{ base: 0, lg: 3 }}
          // paddingRight={{ base: 0, lg: 3 }}
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            alignSelf: "left",
            position: "absolute",
            left: "1rem",
          }}
          className="menu-item"
        >
          <SiteLink href="/" style={{ color: "white" }}>
            <FontAwesomeIcon icon={faArrowLeft} size="1x" />
          </SiteLink>
        </span>
        <span
          style={{
            // width={{
            //   base: "12rem",
            //   sm: "16rem",
            //   lg: "20rem",
            // }}
            width: "20rem",
            whiteSpace: "wrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {series.name_long}
        </span>
      </div>
    </TopBarWrapper>
  );
};

interface IDecksViewProps {
  data: GatsbyTypes.DecksListQuery;
}

const DecksView: React.FC<IDecksViewProps> = ({
  pageContext: { decks, series },
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");
  return (
    <>
      <SEO title={`${series.name_long} study terms`} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: ["base", "sm"].includes("breakpoint")
            ? "100%"
            : "repeat(auto-fit, minmax(330px, 1fr))", // 100% on mobile
          gridGap: ["base", "sm"].includes("breakpoint") ? 0 : "1rem",
          // gridGap: "var(--space-6)",
          maxWidth: "1000px",
          margin: "0 auto",
          padding: ["base", "sm"].includes("breakpoint")
            ? 0
            : "0rem 1rem 0 1rem",
          paddingInlineStart: ["base", "sm"].includes("breakpoint")
            ? 0
            : "1rem",
          paddingInlineEnd: ["base", "sm"].includes("breakpoint") ? 0 : "1rem",
          width: ["base", "sm"].includes("breakpoint") ? "50%" : "100%",
        }}
      >
        {decks.map(({ node: deck }, index) => (
          <DeckItem deck={deck} series={series} key={index} />
        ))}
      </div>
    </>
  );
};

export default DecksView;
