import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useBreakpoint } from "use-breakpoint";

import { LayoutProvider, Paper, SEO } from "@parataxic/shared-ui";

import { TopBar } from "../components/Layout/TopBar";
import { TopBar as VocabTopBar, VocabProvider } from "../templates/Vocab";
import { TopBar as DeckTopBar } from "../templates/Decks";
import { TopBarContext } from "../components/Layout/constants";

import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/400-italic.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/merriweather";

import "@parataxic/shared-ui/src/styles/Layout.css";
import "www/src/styles/global.css";

type LayoutProps = {
  topBarContent?: React.Component;
};

const PAPER_VARIANT_BREAKPOINTS = { full: 0, raised: 768 };
const BREAKPOINTS = {
  base: 0,
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

export const Layout: React.FC<LayoutProps> = ({
  children,
  topBarContent: _topBarContent,
  ...props
}) => {
  const { site, logo, ogImage }: GatsbyTypes.LayoutQuery =
    useStaticQuery<GatsbyTypes.LayoutQuery>(
      graphql`
        query Layout {
          site {
            siteMetadata {
              title
              description
              author
              siteUrl
            }
          }
          logo: file(name: { eq: "logo-white-rounded" }, ext: { eq: ".png" }) {
            childImageSharp {
              gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
            }
          }
          ogImage: file(
            name: { eq: "logo-white-rounded" }
            ext: { eq: ".png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 512, placeholder: NONE, layout: FIXED)
            }
          }
        }
      `
    );

  const customLayout = props?.pageContext?.layout;
  let OuterProvider = (props) => <React.Fragment {...props} />;

  let topBarContent = _topBarContent;
  let TopBarComponent = TopBar;
  let topBarProps = {
    siteTitle: site?.siteMetadata?.title,
    logo: logo,
  };

  if (customLayout === "Vocab") {
    const {
      vocab: {
        deck: { series, ...deck },
        ...vocab
      },
    } = props?.data;
    TopBarComponent = VocabTopBar;
    topBarProps = {
      deck,
      series,
      vocab,
    };
    OuterProvider = ({ children, ...props }) => (
      <VocabProvider vocab={vocab} deck={deck} series={series}>
        {children}
      </VocabProvider>
    );
  } else if (customLayout === "Deck") {
    const { series } = props?.pageContext;
    topBarProps = {
      series,
    };
    TopBarComponent = DeckTopBar;
  }

  const [content, setContent] = React.useState<string | null>(topBarContent);
  const { breakpoint: paperResponsiveVariant } = useBreakpoint(
    PAPER_VARIANT_BREAKPOINTS,
    "elevated"
  );
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");

  return (
    <LayoutProvider siteMetadata={site.siteMetadata}>
      <OuterProvider>
        <TopBarContext.Provider value={{ setContent, content }}>
          <TopBarComponent {...topBarProps} />
          <SEO site={site} imageData={ogImage} />
          <Paper
            as="main"
            style={{
              margin: "0 auto",
              maxWidth: "1000px",
              padding: ["base", "sm"].includes("breakpoint") ? "1rem" : 0,
              height: "calc(100% - 50px)",
            }}
            variant={paperResponsiveVariant}
          >
            {children}
          </Paper>
        </TopBarContext.Provider>
      </OuterProvider>
    </LayoutProvider>
  );
};

export default Layout;
